<template>
  <div>
    <div class="layout-content">
      <div class="router-transitions container-fluid flex-grow-1 container-p-y">
        <MenuApp>
          <router-view />
        </MenuApp>
      </div>
    </div>
    <AuthFooter />

    <SignUpModal
      :show="visibleSignUpModal"
      @close="() => showSignUpModal = false"
    />
  </div>
</template>

<script>
import AuthFooter from '@/components/footer/AuthFooter'
import MenuApp from '@/components/menu/MenuApp'
import SignUpModal from '@/components/modals/SignUpModal'

export default {
  name: 'MainTemplate',
  components: {
    SignUpModal,
    AuthFooter,
    MenuApp
  },
  data () {
    return {
      showSignUpModal: false
    }
  },
  created () {
    this.init()
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters['oauth/isAuthenticated']
    },
    visibleSignUpModal () {
      return this.showSignUpModal
    }
  },
  methods: {
    init () {
      if (this.isAuthenticated === false) {
        setTimeout(() => {
          this.showSignUpModal = true
        }, 180000) // 3 minutes
      }
    }
  }
}
</script>

<style scoped>
  .sheet-container {
    height: calc(100vh - 24px);
    position: relative;
  }

  .container-fluid {
    margin-left: 34px;
    margin-right: 83px;
  }
</style>
