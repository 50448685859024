<template>
  <!---->
  <sui-sidebar
    v-model:visible="toggle"
    icon="labeled"
    inverted
    animation="overlay"
    class="sub-nav-drawer overflow-hidden"
    :class="{[bgColor]: true}"
    dimmed
  >
    <div class="sub-card text-left">
      <h5>{{ $t(title) }}</h5>

      <div class="p-0 maxh-25">
        <div
          class="maxh-25"
          v-for="item in submenu"
          :key="item.id"
          link
        >
          <div class="row">
            <div class="sub-item-text mb-2" @click="clicked(item.route, item)"
            >
              <div class="row">
                <div class="col-1 col-indicator">
                  <div class="d-inline" style="min-width: 30px; margin: 0">
                    <i
                      v-if="item.route==='selectResidential' && activeResidential.residentialId === item.residential.residentialId"
                      class="check icon pb-2 check-icon"
                    ></i>
                  </div>
                </div>
                <div class="col">
                  <span class="d-inline">{{ item.title === 'communications.pqrs' ? $t('communications.pqrs', { PQRSlabel }) : $t(item.title) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </sui-sidebar>
</template>

<script>
import { SuiSidebar } from 'vue-fomantic-ui'
import { mapGetters } from 'vuex'

export default {
  name: 'SubMenu',
  components: { SuiSidebar },
  data () {
    return {
      toggle: this.toggleSubmenu,
      submenu: []
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential', 'residentials']),
    PQRSlabel () {
      return this.activeResidential ? this.activeResidential.PQRSlabel : ''
    }
  },
  props: {
    toggleSubmenu: {
      required: true,
      type: Boolean
    },
    title: {
      type: String,
      required: false
    },
    subMenuItems: {
      type: Array,
      required: true
    },
    bgColor: {
      type: String,
      required: false,
      default: 'sec-bg-color'
    },
    onSubmenuClicked: { type: Function }
  },
  watch: {
    title () {
      this.changeSubmenu()
    },
    toggleSubmenu () {
      this.changeSubmenu()
    },
    PQRSlabel () {
      const PQRSlabel = this.$store.getters['residential/activeResidential'].PQRSlabel
      console.log('PQRSlabel', PQRSlabel)
      return PQRSlabel
    }
  },
  methods: {
    changeSubmenu () {
      if (this.title === 'menu.condominiums') {
        this.submenu = this.residentials
          .filter(res => res.status === 'ACTIVE')
          .map(residential => ({
            id: residential.id,
            title: residential.name,
            route: 'selectResidential',
            residential
          }))
      } else {
        this.submenu = this.subMenuItems
      }
    },

    clicked (route, item) {
      this.$emit('onSubmenuClicked', route, item)
    }
  }
}
</script>

<style scoped>
  .sub-nav-drawer {
    position: absolute;
    width: 234px !important;
    top: 0;
    left: 45px;
    box-shadow: none;
    height: 100vh !important;
    border-radius: 0 !important;
  }
  .sub-card {
    box-shadow: none !important;
    background-color: transparent;
    height: 90% !important;
  }
  .sub-card h5 {
    color: #fff !important;
    margin-left: 16px;
    padding-top: 57px;
    font-size: 22px;
  }
  .sub-item-text {
    color: #fff;
    font-size: 16px !important;
    cursor: pointer;
  }
  .col-indicator {
    margin-left: 15px;
    margin-right: -4px;
  }
  .check-icon {
    color: white;
    font-size: 12px;
    min-width: 15px;
  }
</style>
