<template>
  <div
    v-bind:class="{ 'prim-bg-color': item.isDark }"
    style="margin: 0; border-radius: 0;"
  >
    <div class="py-2" style="padding-right: 2px">
        <div :data-tooltip="$t(item.title)" data-position="right center">
          <img
          data-position="right center"
          class="logo"
          style="width: 24px; height: 24px"
          @click="onClick"
          :src="getIcon()" />
        </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MenuItem',
  props: {
    item: {
      required: true,
      type: Object
    },
    clicked: { type: Function }
  },
  mounted () {

  },
  computed: {
    activeResidential () {
      return this.$store.getters['residential/activeResidential']
    },
    ...mapGetters('socialAreas', [
      'allAreas'
    ])
  },
  methods: {
    onClick () {
      if (this.item.isResidentialRequired) {
        if (this.activeResidential.residentialId === 0) {
          return
        }
        if (this.activeResidential.isMaster && !this.item.mastersCanAccess) {
          this.$swal(this.$t('menu.restricted'), this.$t('menu.restricted1'), 'warning')
          return
        }
        if (this.item.title === 'menu.chargeAccount' && !this.activeResidential.allowChargeAccount) {
          this.$swal(this.$t('menu.pay'), this.$t('menu.pay1'), 'warning')
          return
        }
      }

      let subMenu = this.item.subMenu
      if (this.item.title === 'menu.areas') {
        subMenu = this.allAreas.map(area => {
          return { title: area.name, route: 'spacesManagement', socialAreaId: area.id }
        })
      }

      this.clicked(this.item.title, this.item.route, subMenu)
    },

    getIcon () {
      if (this.item.isResidentialRequired) {
        if (!this.activeResidential.residentialId) {
          return this.item.icon
        }
        if (this.item.title === 'menu.chargeAccount' && !this.activeResidential.allowChargeAccount) {
          return this.item.icon
        }
        if (this.activeResidential.isMaster && !this.item.mastersCanAccess) {
          return this.item.icon
        }
      }
      return this.item.iconActive

      // return (!this.item.isResidentialRequired || this.activeResidential.residentialId > 0)
      //   ? this.item.iconActive
      //   : this.item.icon
    }
  }
}
</script>

<style scoped>
  img {
    cursor: pointer
  }
</style>
