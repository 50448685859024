<template>
  <div>
    <div class="nav-drawer included sec-bg-color" style="z-index:1 !important; background-color: #2186b0 !important">
      <div style="z-index:100 !important; background-color: #2186b0 !important">
        <div class="p-0">
          <div class="menu-logo">
            <!-- <label class="zibi">zibi</label> -->
            <img src="@/assets/img/LogoLizi.svg" class="zibi" />
          </div>
          <div v-for="item in menu.items" :key="item.title">
            <MenuItem
              :item="item"
              :clicked="openChilds"
            />
          </div>
        </div>

        <div class="footer-nav-drawer">
          <MenuItem
            v-for="item in menu.footerItems"
            :key="item.title"
            :item="item"
            :clicked="openChilds"
          />
        </div>
      </div>
  <!-- NEW -->

      <SubMenu
        style="z-index:-100 !important; margin-left: 45px !important"
        :toggleSubmenu="showSubmenu"
        :title="subMenu.title"
        :subMenuItems="subMenu.items"
        :bgColor="subMenu.bgColor"
        @onSubmenuClicked="goToRoute"
      />

  <!-- NEW -->
    </div>
    <div class="pushable pushable-container">
      <div class="pusher">
        <div class="m-0 p-0 h-100" style="padding-right: 42px !important">
          <router-view />
        </div>
      </div>
    </div>

    <!-- Submenu -->
    <!-- <div class="pushable pushable-container">

      <SubMenu
        :toggleSubmenu="showSubmenu"
        :title="subMenu.title"
        :subMenuItems="subMenu.items"
        :bgColor="subMenu.bgColor"
        @onSubmenuClicked="goToRoute"
      />

      <div class="pusher">
        <div class="m-0 p-0 h-100">
          <router-view />
        </div>
      </div>
    </div> -->
    <CustomizedMessageModal
      :show="visibleCustomizedMessageModal"
      @close="() => showCustomizedMessageModal = false"
    />
  </div>
</template>
<script>
import MenuItem from '@/components/menu/MenuItem'
import SubMenu from '@/components/menu/SubMenu'
import CustomizedMessageModal from '@/components/modals/CustomizedMessageModal'
import helpers from '@/utils/helpers'
import menu from '@/utils/menu'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    SubMenu,
    MenuItem,
    CustomizedMessageModal
  },
  data () {
    return {
      toggle: false,
      subMenu: { title: '', items: [], bgColor: '' },
      menu: { items: [], footerItems: [] },
      showCustomizedMessageModal: false
    }
  },
  created () {
    this.$store.dispatch('residential/loadResidentials', null, { root: true }).then((resp) => {
      this.residentials = resp
      this.loadBuildingMenu()
      if (this.residential.residentialId) {
        this.$store.dispatch('socialAreas/load_Areas', this.residential.residentialId, { root: true })
      }
    })
  },
  // updated () {
  //   const pusherDimmedDiv = document.getElementsByClassName('pusher dimmed')[0]
  //   const subMenu = document.getElementsByClassName('ui sidebar left overlay inverted visible vertical labeled icon menu sub-nav-drawer overflow-hidden sec-bg-color')[0]
  //   subMenu.style.height = pusherDimmedDiv.style.height
  // },
  computed: {
    ...mapGetters('oauth', ['authToken']),
    ...mapGetters('residential', ['residentials']),

    showSubmenu () { // testing
      return this.toggle
    },
    visibleCustomizedMessageModal () {
      return this.showCustomizedMessageModal
    },
    residential () {
      return this.$store.getters['residential/activeResidential']
    }
  },
  // watch: {
  //   // residentials () {
  //   //   this.loadBuildingMenu()
  //   // }
  // },
  methods: {
    ...mapActions('spacesManagement', ['setSpaceToManage']),

    loadBuildingMenu () {
      const residentials = this.residentials.filter(x => x.status === this.status.ACTIVE)
      const menuResidential = menu.items.find(x => x.name === 'residentials')
      menuResidential.subMenu = []
      if (residentials.length > 0) {
        menuResidential.subMenu = residentials.map(residential => {
          return {
            id: residential.id,
            title: residential.name,
            route: 'selectResidential',
            residential
          }
        })
      }

      this.menu.items = menu.items
      this.menu.footerItems = menu.footerItems
    },

    openChilds (title, route, subMenu) {
      // Set menu active
      this.menu.items.map(x => (x.active = false))
      let item = this.menu.items.find(x => x.title === title)
      if (item) {
        item.active = true
      } else {
        item = this.menu.footerItems.find(x => x.title === title)
      }

      if (title === 'menu.areas' && !subMenu.length && this.residential.residentialId) {
        this.$swal(this.$t('menu.noSpaces'), '', 'error')
      }

      this.subMenu.title = ''
      this.subMenu.items = []
      if (subMenu && subMenu.length > 0 &&
          (this.residential.residentialId || item.isResidentialRequired === false)) {
        this.subMenu.title = title
        this.subMenu.items = subMenu
        this.subMenu.bgColor = item.isDark ? 'prim-bg-color' : 'sec-bg-color'
        this.toggle = true
      } else {
        if (route &&
            (this.residential.residentialId || item.isResidentialRequired === false)) {
          this.goToRoute(route)
        } else {
          this.subMenu = { title: '', items: [] }
        }
        this.toggle = false
      }
    },

    goToRoute (route, item) {
      switch (route) {
        case 'selectResidential':
          this.setSelectedResidential(item.residential)
          break
        case 'openCustomizedMessageModal':
          helpers.saveRoute('messages/custom')
          this.showCustomizedMessageModal = true
          break
        case '/logout':
          this.logOut()
          break
        case 'selectBoard':
          if (!this.residential.name) {
            this.$router.push({ path: '/' })
          } else {
            this.$router.push({ path: '/board' })
          }
          break
        case 'spacesManagement':
          if (!this.residential.name) {
            this.$router.push({ path: '/' })
          } else {
            this.setSpaceToManage(item)
            this.$store.dispatch('socialAreas/load_analytics', item.socialAreaId, { root: true })
            this.$router.push({ path: '/spacesManagement' })
          }
          break
        case 'profile':
          if (this.authToken) {
            this.$router.push({ path: '/profile' })
          } else {
            this.$swal(this.$t('menu.notRegistered'), '', 'error')
          }
          break
        default:
          this.subMenu = { title: '', items: [] }
          this.$router.push({ path: route }).catch(() => {})
      }
      this.onClickOutside()
    },
    setSelectedResidential (residential) {
      this.$store.dispatch('residential/setActiveResidential', residential)
      this.$router.push({ path: '/residential' })

      this.menu = menu

      this.onClickOutside()
    },

    onClickOutside () {
      this.menu.items.map(x => (x.active = false))
      this.subMenu.title = ''
      this.subMenu.items = []
      this.toggle = false
    },

    include () {
      return [document.querySelector('.included')]
    },

    logOut () {
      this.$store.dispatch('oauth/logOut').then(() => {
        const user = this.$store.getters['user/user']
        this.$socket.emit('user:logOut', user.id)
        this.$router.push({ path: '/' })
      })
    }
  }
}
</script>

<style scoped>
  .zibi {
    font-family: Roboto-Black;
    font-size: 19px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: white
  }

</style>
