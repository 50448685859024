export default {
  items: [
    {
      name: 'home',
      title: 'menu.dashboard',
      icon: require('@/assets/img/icons/icon_home.svg'),
      iconActive: require('@/assets/img/icons/icon_home_active.svg'),
      isDark: true,
      active: false,
      isResidentialRequired: false,
      route: '/home'
    },
    {
      name: 'residentials',
      title: 'menu.condominiums',
      icon: require('@/assets/img/icons/icon_building.svg'),
      iconActive: require('@/assets/img/icons/icon_building_active.svg'),
      isDark: true,
      active: false,
      isResidentialRequired: false,
      subMenu: []
    },
    {
      name: 'communication',
      title: 'menu.communication',
      icon: require('@/assets/img/icons/icon_communication.svg'),
      iconActive: require('@/assets/img/icons/icon_communication_active.svg'),
      isDark: false,
      active: false,
      isResidentialRequired: true,
      mastersCanAccess: false,
      subMenu: [
        {
          title: 'communications.billboard',
          route: 'selectBoard' // Board
        },
        {
          title: 'communications.broadcast',
          // route: '/messaging/massive'
          route: '/messaging'
        },
        {
          title: 'communications.pqrs',
          // route: '/messaging/massive'
          route: '/pqrs'
        },
        {
          title: 'communications.surveys',
          // route: '/messaging/massive'
          route: '/surveys'
        }
        // {
        //   title: 'communications.custom',
        //   route: '/messaging/direct'
        // }
      ]
    },
    {
      name: 'areas',
      title: 'menu.areas',
      icon: require('@/assets/img/icons/icon_calendar.svg'),
      iconActive: require('@/assets/img/icons/icon_calendar_active.svg'),
      isDark: false,
      active: false,
      isResidentialRequired: true,
      mastersCanAccess: true,
      subMenu: [
        { title: 'areas.swimmingPool', route: 'spacesManagement' },
        { title: 'areas.room', route: 'spacesManagement' },
        { title: 'areas.gym', route: 'spacesManagement' },
        { title: 'areas.parking', route: 'spacesManagement' },
        { title: 'areas.parkland', route: 'spacesManagement' }
      ]
    },
    {
      title: 'menu.residents',
      icon: require('@/assets/img/icons/icon_resident.svg'),
      iconActive: require('@/assets/img/icons/icon_resident_active.svg'),
      isDark: false,
      active: false,
      isResidentialRequired: true,
      mastersCanAccess: false,
      route: '/residents'
    },
    {
      title: 'menu.guard',
      icon: require('@/assets/img/icons/icon_shield.svg'),
      iconActive: require('@/assets/img/icons/icon_shield_active.svg'),
      isDark: false,
      active: false,
      isResidentialRequired: true,
      mastersCanAccess: true,
      // route: '/guard'
      subMenu: [
        { title: 'guard.watchmen', route: '/guard/watchmen' },
        { title: 'guard.visits', route: '/guard/visits' },
        { title: 'guard.shortstay', route: '/guard/shortstay' },
        { title: 'guard.notifications', route: '/guard/notifications' }
      ]
    },
    {
      title: 'menu.chargeAccount',
      icon: require('@/assets/img/icons/icon_charge2.svg'),
      iconActive: require('@/assets/img/icons/icon_charge2_active.svg'),
      isDark: false,
      active: false,
      isResidentialRequired: true,
      route: '/chargeAccount'
    }
  ],
  footerItems: [
    {
      title: 'menu.profile',
      icon: require('@/assets/img/icons/icon_profile.svg'),
      iconActive: require('@/assets/img/icons/icon_profile_active.svg'),
      isDark: true,
      active: true,
      isResidentialRequired: false,
      route: '/profile'
    },
    {
      title: 'menu.logOut',
      icon: require('@/assets/img/icons/icon_exit.svg'),
      iconActive: require('@/assets/img/icons/icon_exit_active.svg'),
      isDark: true,
      active: true,
      isResidentialRequired: false,
      route: '/logout'
    }
  ]
}
