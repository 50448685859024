<template>
  <SuiModal v-model="dialog">
    <SuiModalContent image style="padding: 80px 70px">
      <a class="close-modal" @click="onClose">
        <i
          class="times icon general-blue-color"
          style="font-size: 25px"
        ></i>
      </a>
      <SuiImage
        wrapped
        size="large"
        :src="require('@/assets/img/modal-image.png')"
      />
      <h1>hey</h1>
      <SuiModalDescription>
        <div class="pt-5">
          <span class="title general-blue-color">{{$t('thanksSection')}}</span>
          <p
            class="text-content modal-text-content mt-3 py-3 mb-4"
            v-html="$t('preparingDetails', { email: emails.contact })"
          ></p>
          <Button
            color="secondary"
            :text="$t('buttons.close')"
            class="d-inline ml-1"
            @clicked="onClose" />
        </div>
      </SuiModalDescription>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import { SuiModal, SuiModalContent, SuiImage, SuiModalDescription } from 'vue-fomantic-ui'
import Button from '@/components/form/Button'

export default {
  name: 'CustomizedMessageModal',
  components: { SuiModal, SuiModalContent, SuiImage, SuiModalDescription, Button },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['close'],
  computed: {
    dialog () {
      return this.show
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
  .title {
    font-size: 26px !important;
    font-weight: bold;
  }
  .text-content {
    font-size: 20px;
  }
</style>
