<template>
    <SuiModal basic v-model="dialog">
        <SuiModalContent >
          <div class="signIn-container">
            <a @click="close" >
              <i style="text-decoration:none !important; cursor: pointer;font-size: 25px; position: relative; left: 89%; top: 10px;"
                class="times icon general-blue-color"
              ></i>
            </a>
            <SuiModalDescription>
                <div class="align-items-center div-form">
                  <img class="logo" style="cursor: auto" src="@/assets/img/logo_lizi.png" />
                  <div class="w-100">
                    <h5 class="my-4 mx-5 text-center" style="margin-bottom: 15px !important">{{ $t('createYourAccount') }}</h5>
                    <form
                      @submit.prevent="onSubmit"
                      novalidate
                      class="ui form fields"
                      :class="[{'blue double loading':isLoading}]"
                      autocomplete="off">

                      <TextField
                        style="width: 100% !important"
                        :model-value="form.email"
                        @update:model-value="form.email=$event"
                        type="email"
                        :label="$t('form.email')"
                        autocomplete="off"/>

                      <TextField
                        style="width: 100% !important; margin-top: 7px"
                        :model-value="form.repeatEmail"
                        @update:model-value="form.repeatEmail=$event"
                        type="email"
                        :label="$t('form.confirmEmail')"
                        autocomplete="off" />

                      <TextField
                        style="width: 100% !important; margin-top:7px"
                        :model-value="form.password"
                        @update:model-value="form.password=$event"
                        type="password"
                        :label="$t('form.password')"
                        :show="true"
                        autocomplete="off" />

                      <div class="level-container" style="margin-bottom: 0">
                        <div :class="strength_level" style="margin-bottom: 0"></div>
                      </div>

                      <Button
                        style="align-self:center; margin-top:15px !important"
                        color="primary"
                        :text="$t('buttons.signup')"
                        @clicked="onSubmit"
                      />

                      <div style="height: 15px; margin-top: 5px; align-self:center" v-if="(v$.form.email.$error || v$.form.repeatEmail.$error || v$.form.password.$error || error)">
                        <span class="text-danger"  v-if="v$.form.email.$error">
                          {{v$.form.email.$errors[0].$message }}
                        </span>
                        <span v-else-if="v$.form.repeatEmail.$error" class="text-danger">
                          {{v$.form.repeatEmail.$errors[0].$message }}
                        </span>
                        <span v-else-if="v$.form.password.$error && !error" class="text-danger">
                          {{ v$.form.password.$errors[0].$message}}
                        </span>
                        <span v-else-if="error" class="text-danger">{{$t('errors.emailTaken')}}</span>
                      </div>
                    </form>
                  </div>
                </div>
            </SuiModalDescription>
          </div>
        </SuiModalContent>
    </SuiModal>
</template>

<script>
import { SuiModal, SuiModalContent, SuiModalDescription } from 'vue-fomantic-ui'
import Button from '@/components/form/Button'
import TextField from '@/components/form/TextField'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators/dist/raw.esm'
import globals from '@/utils/globals'
import { mapActions } from 'vuex'

export default {
  name: 'SignUpModal',
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    TextField,
    Button
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['close'],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      isLoading: false,
      form: {
        email: '',
        repeatEmail: '',
        password: ''
      },
      error: false,
      strength: 0
    }
  },
  validations () {
    return {
      form: {
        email: {
          required: helpers.withMessage(this.$t('validations.email.required'), required),
          email: helpers.withMessage(this.$t('validations.email.invalid'), email)
        },
        repeatEmail: {
          required: helpers.withMessage(this.$t('validations.email.repeatRequired'), required),
          sameAs: helpers.withMessage(this.$t('validations.email.match'), function (value) { return this.form.email === value })
        },
        password: {
          required: helpers.withMessage(this.$t('validations.password.required'), required),
          minLength: helpers.withMessage(this.$t('validations.password.miniumCharacters', { number: globals.passwordRules.minLength }), minLength(globals.passwordRules.minLength)),
          upperCase: helpers.withMessage(this.$t('validations.password.uppercaseCharacters'), (value) => /(?=.*[A-Z])/.test(value)
          ),
          numberIn: helpers.withMessage(this.$t('validations.password.numberCharacters'), (value) => /(?=.*\d)/.test(value))
        }
      }
    }
  },
  computed: {
    dialog () {
      return this.show
    },
    strength_level: function () {
      return `level level-${this.strength}`
    }
  },
  watch: {
    'form.password': function () {
      this.v$.form.password.$touch()
      this.error = false
      this.strength = 4 - this.v$.form.password.$errors.length
    }
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    async onSubmit () {
      this.error = false
      this.v$.$validate()
      const formValid = !(this.v$.$error)
      if (formValid) {
        // this.isLoading = true
        this.setLoading(true)
        const payload = {
          email: this.form.email,
          password: this.form.password
        }
        this.$store.dispatch('oauth/signUp', payload).then(() => {
          location.reload()
        }).catch(({ status }) => {
          switch (status) {
            case 409:
              this.error = true
              break
            default:
              break
          }
        }).finally(() => {
          // this.isLoading = false
          this.setLoading(false)
        })
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
  @media only screen and (min-width: 768px) {
    .ui.modal {
      width: 400px !important;
      margin: 0 !important;
    }
  }
  .div-form {
    padding: 50px !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .logo {
    max-height: 150px !important;
    max-width: 150px !important;
  }

  .signIn-container {
    background-color: #fff;
    width: 350px;
    height: 570px;
    border-radius: 20px;
  }

  h5 {
    font-family: inherit !important;
    font-size: 23px !important;
  }

  .fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 !important;
  }

  /* -------- Strength Meter  -------- */
  .level-container {
    display: flex !important;
    width: 100% !important;
    margin-top: 0.5rem !important;
  }
  .level-container .level {
    height: 5px !important;
    transition: all 0.5s !important;
  }
  .level-container .level-1 {
    background-color: #ff1a1a !important;
    width: 3.3rem !important;
  }
  .level-container .level-2 {
    background-color: yellow !important;
    width: 10rem !important;
  }
  .level-container .level-3 {
    background-color: limegreen !important;
    width: 16.6rem !important;
  }
  .level-container .level-4 {
    background-color: cadetblue !important;
    width: 20rem !important;
  }

</style>
